<template>
  <div class="videoPanel" :id="locationId"></div>
</template>

<script>
import 'xgplayer';
import FlvPlayer from "xgplayer-flv.js";
export default {
    inheritAttrs: false,
    props: ['locationId', 'url'],
    data() {
        return {
            player: null,
            lastDecodedFrame: 0,
            sameCount: 0,
            currentTime: 0, //当前时间
            lastCurrentTime: 0, //最后的时间
            timer: null, //定时器
        }
    },
    created() {
        //15秒定时任务判断画面时间是否没变化，没变化就重连
        let that = this;
        that.timer = setInterval(() => {
            if (that.lastCurrentTime == that.currentTime) {
                console.log("15秒定时任务判断画面时间没变化，销毁重连", new Date());
                that.currentTime = 0;
                that.lastCurrentTime = 0;
                if (that.player) { //如果有实例，就销毁
                    that.player.destroy(); //销毁 
                }
                that.initPlayer() //重连
            } else {
                that.currentTime = that.player.currentTime
            }
        }, 15 * 1000);
    },
    watch: {
        url: {
            handler(newval) {
                this.player.src = newval
            }
        }
    },
    mounted() {
        this.initPlayer();
        //检测网络连接，销毁重连
        let that = this;
        window.addEventListener("online", () => {
            if (that.player) {
                that.currentTime = 0;
                that.lastCurrentTime = 0;
                that.player.destroy(); //销毁 
                that.initPlayer() //重连
            }
        });
        //12小时后，销毁重连
        setTimeout(() => {
            console.log("12小时后，销毁重连");
            if (that.player) {
                that.currentTime = 0;
                that.lastCurrentTime = 0;
                that.player.destroy(); //销毁 
                that.initPlayer() //重连
            }
        }, 12 * 60 * 60 * 1000);
    },
    methods: {
        initPlayer() {
            this.player = new FlvPlayer({
                id: this.locationId,
                url: this.url,
                width: "100%",
                height: "500px",
                volume: 0.0, // 初始音量
                autoplay: true, // 自动播放
                playsinline: true,
                isLive: true,
                screenShot: true, //显示截图按钮
                cors: true
            });
            this.player.once('error', () => {
                if (this.player) {
                    this.player.replay();
                }
                return;
            });
            let that = this;
            //检测画面是否卡死。如果decodedFrames不再发生变化,就销毁掉该实例并进行重新连接。这个函数基本是1秒执行一次的。
            this.player.on("statistics_info", function (res) {
                if (that.lastDecodedFrame == 0) {
                    that.lastDecodedFrame = res.decodedFrames;
                    return;
                }
                if (that.lastDecodedFrame != res.decodedFrames) {
                    that.lastDecodedFrame = res.decodedFrames;
                    that.lastCurrentTime = that.player.currentTime; //最后的时间
                    that.sameCount = 0;
                } else {
                    that.sameCount++;
                    console.log("decodedFrames没有发生变化第", that.sameCount, "次。时间：", new Date());

                    if (that.sameCount > 9) {
                        //decodedFrames10次不变，则判断为画面卡死，销毁实例进行重连
                        console.log("画面卡死，进行重连————————", "时间", new Date());
                        that.lastDecodedFrame = 0;
                        that.sameCount = 0;

                        if (that.player) {
                            that.currentTime = 0;
                            that.lastCurrentTime = 0;
                            that.player.destroy(); //销毁 
                            that.initPlayer() //重连
                        }
                    }
                }
                console.log("最后的时间：", that.lastCurrentTime, "   10秒前的时间：", that.currentTime);
            });
        }
    },
    beforeDestroy() {
        clearInterval(this.timer);
        if (this.player) {
            this.player.destroy();
        }
        //销毁监听
        let that = this;
        window.removeEventListener('online', () => {
            if (that.player) {
                that.currentTime = 0;
                that.lastCurrentTime = 0;
                that.player.destroy(); //销毁 
                that.initPlayer() //重连
            }
        }, true);
    }
}
</script>

<style scoped>
.videoPanel {
  width: 100%;
  height: 240px;
  background-color: #EBEEF5;
  text-align: center;
  color: gray;
  font-size: 16px;
}
</style>