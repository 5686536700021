<template>
  <div>
    <!-- <v-video-player :locationId="data.stream_name" :url="data.ws_url"></v-video-player> -->
    <v-jessibuca-player :id="data.stream_name" :src="data.ws_url" ref="jsPlayer"></v-jessibuca-player>
  </div>
</template>

<script>
import vVideoPlayer from './VideoPlayer.vue';
import vJessibucaPlayer from './JessibucaPlayer.vue';
export default {
  components: {
    vVideoPlayer,
    vJessibucaPlayer
  },
  inheritAttrs: false,
  props: {
    data: Object
  },
  data() {
    return {
    }
  },
  created() {
  },
  mounted() {
  },
  methods: {
  }
}
</script>